import React, { Fragment, Component } from "react";
import Header from "./../Layout/Header";

import ExhibitionSlider1 from "./../Element/ExhibitionSlider1";

//images/

class CompanyExhibition extends Component {

  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content bg-white">
    
          {/*  Inner Page Banner */}
          <section
            className="content-inner exhibition-bx"
            data-content="EXHIBITION"
          >
            <ExhibitionSection />
          </section>
          <div className="section-full">
          </div>
        </div>
      </Fragment>
    );
  }
}

function ExhibitionSection() {
  return (
    <>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 ">
            <ExhibitionSlider1 />
          </div>
          <div className="col-lg-6 m-b30">
            <div className="section-head">
              <p>
                At ÜBER Property Development, we believe in the art of building
                excellence. Our portfolio of high-end, luxury properties
                reflects a relentless pursuit of perfection, where each project
                surpasses the last in innovation, quality, and elegance.{" "}
              </p>
              <p>
                Join us on a journey of continuous improvement and discover how
                we transform visions into iconic landmarks.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { ExhibitionSection };

export default CompanyExhibition;
