import React, { Fragment, Component } from "react";
import WOW from "wowjs";
import { ProDetail } from "./Data";
import Detail3Owl from "./Detail3Owl";
import Header2 from "../../Layout/Header2";

const detailBlog = [
  {
    icon: <i className="ti ti-user" />,
    title: "ARCHITECT",
    subtitle: "Cimato Moroldo"
  },
  {
    icon: <i className="ti ti-location-pin" />,
    title: "LOCATION",
    subtitle: "Hyde Park, South Africa"
  },
  {
    icon: <i className="ti ti-ruler-alt-2" />,
    title: "SIZE",
    subtitle: " 38 000 sqm"
  },
  {
    icon: <i className="ti ti-home" />,
    title: "TYPE",
    subtitle: "Multiuse"
  }
];
class ArdenwoldManor extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    return (
      <Fragment>
        <Header2 />
        <div className="page-content bg-white">
          {/*  Project Img  */}
          <div
            className="banner-three dlab-bnr-inr"
            style={{
              backgroundImage:
                "url(https://cdn.uberpd.co.za/img/projects/hyde-park/hyde-park.jpg)",
              backgroundPosition: "center",
              backgroundColor: "white",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain"
            }}
            data-content="HOME"
            id="sidenav_home"
          />
          {/*  Info Carousel  */}
          <div
            className="section-full content-inner-2 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="container-fluid">
              {/* <Detail3Owl /> */}
            </div>
          </div>
          {/*  Info Carousel */}

          {/*  Project Info  */}
          <section className="section-full content-inner">
            <div className="container">
              <div className="section-head">
                <span>UNDER CONSTRUCTION</span>
                <h4 className="title">HYDE PARK CORNER</h4>
              </div>
              <p>
                Hyde Park Corner stands as a beacon of luxury and modern living,
                redefining the skyline with its impressive 20-storey structure.
                This premier development combines opulent residential units, a
                state-of-the-art wellness centre, and a high-end restaurant,
                offering a lifestyle of unparalleled sophistication and
                convenience.
              </p>
              <h3 className="m-b30">
                <b>Property Overview</b>
              </h3>
              <p>
                <b>Building Details</b>
              </p>

              <ul style={{ listStyleType: "disc" }} className="m-b30">
                <p className="m-b30" style={{ marginLeft: "3%" }}>
                  <b>Height:</b> 20 storeys
                </p>
                <p className="m-b30" style={{ marginLeft: "3%" }}>
                  <b>Total Units:</b> 196
                </p>
                <p className="m-b30" style={{ marginLeft: "3%" }}>
                  <b>Prices:</b> R70,000/sqm
                </p>
              </ul>

              <p>
                <b>Design and Features</b>
              </p>

              <ul style={{ listStyleType: "disc" }} className="m-b30">
                <p className="m-b30" style={{ marginLeft: "3%" }}>
                  <b>Gym & Wellness Centre:</b> Spanning 10,050 sqm, this 6-star
                  facility offers residents access to top-tier fitness and
                  wellness services across three floors.
                </p>
                <p className="m-b30" style={{ marginLeft: "3%" }}>
                  <b>Restaurant:</b> "Decadence," a luxury restaurant, occupies
                  2,140 sqm and promises to be the best of South African fine
                  dining.
                </p>
                <p className="m-b30" style={{ marginLeft: "3%" }}>
                  <b>Residential Units:</b> Covering 25,680 sqm (excluding
                  balconies), the 196 luxury units are fully furnished with the
                  finest finishes, including exclusive penthouses that epitomise
                  high-end living.
                </p>
              </ul>

              <p>
                <b>Location and Accessibility</b>
              </p>

              <p className="m-b30">
                Situated in the prestigious Hyde Park area, this development
                offers easy access to major business districts, high-end
                shopping centres, and top educational institutions. The
                strategic location ensures that residents enjoy both the
                tranquillity of a secluded environment and the vibrancy of urban
                life. <br />
                <br />
                Hyde Park Corner is not just a residence; it's a statement of
                refined living. This development sets a new standard for luxury
                and convenience, making it a prime choice for discerning
                homeowners seeking an exceptional lifestyle.
              </p>
              <div className="row m-b30 widget widget_getintuch widget_getintuch-pro-details pro-details-col-5 m-lr0">
                <ProDetail detailBlog={detailBlog} />
              </div>
            </div>
          </section>
          {/*  Project Info  End*/}
        </div>
      </Fragment>
    );
  }
}

export default ArdenwoldManor;
