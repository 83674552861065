import React, { Fragment, Component } from "react";
import WOW from "wowjs";
import Header from "../../Layout/Header";
import { ProDetail } from "./Data";
import Detail3Owl from "./Detail3Owl";
import Header2 from "../../Layout/Header2";

const detailBlog = [
  {
    icon: <i className="ti ti-user" />,
    title: "ARCHITECT",
    subtitle: "Roxanne Berti",
  },
  {
    icon: <i className="ti ti-location-pin" />,
    title: "LOCATION",
    subtitle: "Ardenwold, South Africa",
  },
  {
    icon: <i className="ti ti-ruler-alt-2" />,
    title: "SIZE",
    subtitle: "1 400 sqm",
  },
  {
    icon: <i className="ti ti-home" />,
    title: "TYPE",
    subtitle: "Residential",
  },
];

const latestBlog = [
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/ardenald-manor/BRADFORD C5 2024.png",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/ardenald-manor/BRADFORD C6 2024.png",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/ardenald-manor/BRADFORD C4 (1).png",
  },

  {
    image:
      "https://cdn.uberpd.co.za/img/projects/ardenald-manor/BRADFORD C1 (5).png",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/ardenald-manor/BRADFORD C3 (1).png",
  },

  {
    image:
      "https://cdn.uberpd.co.za/img/projects/ardenald-manor/BRADFORD C3 (3).png",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/ardenald-manor/BRADFORD C3.png",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/ardenald-manor/BRADFORD C3 (2).png",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/ardenald-manor/BRADFORD C1 (3).png",
  },
];
class ArdenwoldManor extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    return (
      <Fragment>
        <Header2 />
        <div className="page-content bg-white">
          {/*  banner  */}
          <div
            className="banner-three dlab-bnr-inr"
            style={{
              backgroundImage:
                "url(https://cdn.uberpd.co.za/img/projects/ardenald-manor/header/DSC00875.png)",
            }}
            data-content="HOME"
            id="sidenav_home"
          ></div>

          {/*  Project Img  */}
          {/*  Info Carousel  */}
          <div
            className="section-full content-inner-2 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div
              className="container-fluid"
              style={{ maxWidth: "1700px", margin: "auto" }}
            >
              <Detail3Owl latestBlog={latestBlog} />
            </div>
          </div>
          {/*  Info Carousel */}

          {/*  Project Info  */}
          <section className="section-full content-inner">
            <div className="container">
              <div className="section-head">
                <span>DEVELOPED 2020</span>
                <h4 className="title">ARDENWOLD MANOR</h4>
              </div>
              <p>
                Nestled on the serene banks of the Vaal River, Ardenwold Manor
                stands as a testament to luxury and refined living. This
                double-storey mansion offers an exceptional lifestyle, combining
                elegance with a plethora of recreational amenities.
              </p>
              <h3 className="m-b30">
                <b>Property Overview</b>
              </h3>
              <p>
                <b>Ground Floor</b>
              </p>
              <p>
                The grand entrance opens into a spacious reception area, setting
                the tone for the rest of the home. Expansive living and dining
                areas flow seamlessly, designed for both relaxation and
                entertaining. A gourmet kitchen with top-of-the-line appliances
                and granite countertops, accompanied by a scullery and pantry,
                caters to all culinary needs. The living spaces extend to a
                covered patio overlooking the lush gardens and the tranquil Vaal
                River.
              </p>
              <p>
                <b>First Floor</b>
              </p>
              <p>
                The first floor features luxurious bedrooms, each with en-suite
                bathrooms and built-in wardrobes. The master suite offers a
                private balcony with stunning river views, an opulent en-suite
                bathroom, and a walk-in closet. Additional bedrooms are designed
                with comfort and style in mind, providing ample space and
                beautiful finishes.
              </p>
              <p>
                <b>Recreational Amenities</b>
              </p>
              <ul style={{ listStyleType: "disc" }} className="m-b30">
                <p className="m-b30" style={{ marginLeft: "3%" }}>
                  <b>Swimming Pool:</b> A large, inviting pool perfect for
                  leisurely swims and outdoor relaxation.
                </p>
                <p className="m-b30" style={{ marginLeft: "3%" }}>
                  <b>Beach Volleyball Court:</b> Ideal for family fun and
                  friendly competitions
                </p>
                <p className="m-b30" style={{ marginLeft: "3%" }}>
                  <b>Tennis Courts:</b> Well-maintained courts for tennis
                  enthusiasts.
                </p>
              </ul>
              <p>
                <b>Location and Accessibility</b>
              </p>

              <p className="m-b30">
                Ardenwold Manor's prime location on the Vaal River offers
                unparalleled scenic beauty and tranquillity, providing easy
                access to local amenities while ensuring privacy and
                exclusivity. <br />
                <br />
                Ardenwold Manor is more than a home; it is a sanctuary that
                epitomizes luxury, comfort, and an active lifestyle, making it a
                coveted retreat for those seeking the finest in riverfront
                living.
              </p>
              <div className="row m-b30 widget widget_getintuch widget_getintuch-pro-details pro-details-col-5 m-lr0">
                <ProDetail detailBlog={detailBlog} />
              </div>
            </div>
          </section>
          {/*  Project Info  End*/}
        </div>
      </Fragment>
    );
  }
}

export default ArdenwoldManor;
