import React, { Fragment, Component } from "react";
import WOW from "wowjs";
import { ProDetail } from "./Data";
import Detail3Owl from "./Detail3Owl";
import Header2 from "../../Layout/Header2";
import YouTube from "react-youtube";

const detailBlog = [
  {
    icon: <i className="ti ti-user" />,
    title: "ARCHITECT",
    subtitle: "Julian Michaels",
  },
  {
    icon: <i className="ti ti-location-pin" />,
    title: "LOCATION",
    subtitle: "Douglasdale, South Africa",
  },
  {
    icon: <i className="ti ti-ruler-alt-2" />,
    title: "SIZE",
    subtitle: "425 sqm (per unit)",
  },
  {
    icon: <i className="ti ti-home" />,
    title: "TYPE",
    subtitle: "Residential",
  },
];

const latestBlog = [
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/Mont-saint-michel/new/Entrance.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/Mont-saint-michel/new/Entertainment Area.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/Mont-saint-michel/new/Livingroom.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/Mont-saint-michel/new/Bedroom.jpg",
  },

  {
    image:
      "https://cdn.uberpd.co.za/img/projects/Mont-saint-michel/new/Patio.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/Mont-saint-michel/new/Staircase.jpg",
  },
];
class MontSaintMichel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: false,
      posterVisible: false,
      buttonVisible: true,
    };
  }
  componentDidMount() {
    new WOW.WOW().init();
  }
  handleClick = () => {
    this.setState((prevState) => ({
      showVideo: !prevState.showVideo,
      posterVisible: false,
    }));
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      showVideo: prevState.showVideo,
      posterVisible: !prevState.posterVisible,
      buttonVisible: false,
    }));
  };
  render() {
    return (
      <Fragment>
        <Header2 />
        <div className="page-content bg-white">
          {/*  banner  */}
          <div
            className="banner-three dlab-bnr-inr"
            style={{
              backgroundImage:
                "url(https://cdn.uberpd.co.za/img/projects/Mont-saint-michel/new/Font-shot.jpg)",
            }}
            data-content="HOME"
            id="sidenav_home"
          />

          <div
            className="section-full content-inner-2 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div
              className="container-fluid"
              style={{ maxWidth: "1700px", margin: "auto" }}
            >
              <Detail3Owl latestBlog={latestBlog} />
            </div>
          </div>

          {/*  Info Carousel */}

          {/*  Project Info  */}
          <section className="section-full content-inner">
            <div className="container">
              <div className="section-head">
                <span>DEVELOPED 2005</span>
                <h4 className="title">MONT SAINT MICHEL</h4>
                {this.state.buttonVisible && (
                  <button
                    onClick={this.handleToggle}
                    style={{
                      backgroundColor: "#0d8a45",
                      border: "none",
                      borderRadius: "5px",
                      padding: "10px 20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      style={{
                        color: "#ffffff",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      Click Here To Watch Our Video
                    </a>
                  </button>
                )}

                <div style={{ position: "relative" }}>
                  {this.state.showVideo && (
                    <YouTube videoId="UjJZ4FgeyTM" className="iframe-youtube" />
                  )}
                  {this.state.posterVisible && (
                    <div onClick={this.handleClick} className="poster-image">
                      <img
                        src="https://cdn.uberpd.co.za/img/yotube-posters /pngegg.png"
                        alt="Play Button"
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <p>
                Mont Saint Michel Estate, located in the heart of Douglasdale,
                offers a double-storey residential dwelling that epitomises
                luxury and modern living. This property provides an ideal blend
                of elegance and comfort, designed to meet the needs of
                discerning homeowners.
              </p>
              <h3 className="m-b30">
                <b>Property Overview</b>
              </h3>
              <p>
                <b>Ground Floor</b>
              </p>
              <p>
                The front door opens into a welcoming reception area, leading to
                the lounge, dining room, and kitchen. The spacious kitchen,
                featuring granite countertops and ample storage, flows into the
                scullery and garage. The living area extends to a covered patio,
                offering seamless indoor-outdoor living, perfect for family
                gatherings by the pool and garden.
              </p>
              <p>
                <b>First Floor</b>
              </p>
              <p>
                The first floor comprises three bedrooms, each with access to
                balconies, providing beautiful views and fresh air. The master
                bedroom includes an en-suite bathroom and a spacious closet. An
                additional bathroom, finished to high standards, serves the
                other bedrooms.
              </p>
              <p>
                <b>Construction Details</b>
              </p>
              <p
                style={{
                  listStyleType: "disc",
                  marginLeft: "3%",
                  marginTop: "3%",
                }}
              >
                <b>Roof:</b> Pitched roof with clay tiles supported by wooden
                trusses.
              </p>
              <p
                style={{ listStyleType: "disc", marginLeft: "3%" }}
                className="m-b30"
              >
                <b>External Walls:</b> Plastered and painted brickwork.
              </p>
              <p
                style={{ listStyleType: "disc", marginLeft: "3%" }}
                className="m-b30"
              >
                <b>Internal Walls:</b> Plastered and painted brickwork with
                wooden cornices and window frames.
              </p>
              <p
                style={{ listStyleType: "disc", marginLeft: "3%" }}
                className="m-b30"
              >
                <b>Floors:</b> Combination of ceramic tiles and wooden laminate.
              </p>
              <p
                style={{ listStyleType: "disc", marginLeft: "3%" }}
                className="m-b30"
              >
                <b>External Floors:</b> Brick paving and ceramic tiles.
              </p>
              <p className="m-b30">
                Mont Saint Michel Estate provides an unparalleled living
                experience, combining luxury, comfort, and modern convenience.
                This home is a prime opportunity for those seeking a refined
                lifestyle in a vibrant community.
              </p>
              <div className="row m-b30 widget widget_getintuch widget_getintuch-pro-details pro-details-col-5 m-lr0">
                <ProDetail detailBlog={detailBlog} />
              </div>
            </div>
          </section>
          {/*  Project Info  End*/}
        </div>
      </Fragment>
    );
  }
}

export default MontSaintMichel;
