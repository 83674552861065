import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const latestBlog2 = [
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (14).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (1).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (5).jpg",
  },

  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (9).jpg",
  },

  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (10).jpg",
  },

  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (15).jpg",
  },

  {
    image:
      "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (13).jpg",
  },

  // {
  //   image:
  //     "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (3).jpg",
  // },
  // {
  //   image:
  //     "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (4).jpg",
  // },

  // {
  //   image:
  //     "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (6).jpg",
  // },
  // {
  //   image:
  //     "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (7).jpg",
  // },
  // {
  //   image:
  //     "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (8).jpg",
  // },

  // {
  //   image:
  //     "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (11).jpg",
  // },
  // {
  //   image:
  //     "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (12).jpg",
  // },

  // {
  //   image:
  //     "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (16).JPG",
  // },
  // {
  //   image:
  //     "https://cdn.uberpd.co.za/img/projects/nicol-corner/new/nicol-corner (17).jpg",
  // },
];
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev la la-angle-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

class Slider1 extends Component {
  render() {
    var settings = {
      arrows: true,
      centerMode: true,
      centerPadding: "500px",
      slidesToShow: 1,
      navSpeed: 6000,
      autoplaySpeed: 6000,
      infinite: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 1,
            centerPadding: "400px",
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            centerPadding: "250px",
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            centerPadding: "250px",
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: "200px",
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            centerPadding: "90px",
          },
        },
      ],
    };
    return (
      <Slider
        className="amenities-carousel owl-carousel owl-btn-center-lr "
        {...settings}
      >
        {latestBlog2.map((item, index) => (
          <div className="items" key={index}>
            <div className="amenit-box" style={{ border: "0px solid #fff" }}>
              <div className="media">
                <Link to={"#"} style={{ width: "100%" }}>
                  <img
                    src={item.image}
                    alt=""
                    style={{
                      width: "100%",
                      height: "400px",
                      objectFit: "cover",
                    }}
                    className="sliderstuff2"
                  />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}

export default Slider1;
