import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import logo from "./../../images/logo.png";

class Header extends Component {
  componentDidMount() {
    // sidebar open/close

    var Navicon = document.querySelector(".navicon");
    var sidebarmenu = document.querySelector(".myNavbar ");

    function toggleFunc() {
      sidebarmenu.classList.toggle("show");
      Navicon.classList.toggle("open");
    }
    Navicon.addEventListener("click", toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(document.querySelectorAll(".navbar-nav > li"));
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      navUl.forEach((el) =>
        current !== el ? el.classList.remove("open") : ""
      );

      setTimeout(() => {
        current.classList.toggle("open");

        /* alert(current.className.indexOf('open'));
				if(current.className.indexOf('open') == -1){
					current.classList.toggle('open');
					alert(2);
				}else{
				alert(current.parentNode);
					current.parentNode.classList.add('khelesh');
				} */
      }, 100);
    }
  }
  render() {
    return (
      <Fragment>
        {/*  header  */}
        <header className="site-header header-transparent">
          <HeaderContent />
        </header>
        {/*  header End  */}
      </Fragment>
    );
  }
}
function HeaderContent() {
  return (
    <>
      <div className="top-bar">
        <div className="container-fluid">
          <div className="row d-flex justify-content-md-between justify-content-center align-items-center">
            <div className="dlab-topbar-left">
              <ul>
                <li>
                  <i className="la la-phone-volume"></i> 0800-123456
                </li>
                <li>
                  <i className="las la-map-marker"></i> 1073 W Stephen Ave,
                  Clawson
                </li>
              </ul>
            </div>
            <div className="dlab-topbar-right">
              <ul>
                <li>Monday - Saturday | 08:00 - 17:00</li>
                <li>Email address details</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*   main header  */}
      <div
        className="sticky-header main-bar-wraper navbar-expand-lg"
        style={{ position: "fixed", minWidth: "100vw" }}
      >
        <div className="main-bar clearfix ">
          <div className="container-fluid clearfix">
            {/* website logo */}
            <div className="logo-header mostion logo-dark">
              <a href="/">
                <img
                  src="https://cdn.uberpd.co.za/img/logos/Uber-Property-Developments-Logo-Website.png"
                  alt=""
                />
              </a>
            </div>
            {/* nav toggle button  */}
            <button
              className="navbar-toggler collapsed navicon justify-content-end "
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ zIndex: "100" }}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            {/*extra nav  */}

            <div
              className="header-nav navbar-collapse collapse myNavbar justify-content-end"
              id="navbarNavDropdown"
              style={{ paddingRight: "15px", paddingLeft: "15px" }}
            >
              <div className="logo-header d-md-block d-lg-none">
                <a href="/">
                  {" "}
                  <img
                    src="https://cdn.uberpd.co.za/img/logos/Uber-Property-Developments-Logo-Website.png"
                    alt=""
                  />{" "}
                </a>
              </div>
              <ul className="nav navbar-nav navbar">
                <li className="active">
                  <a href="/">Home</a>
                </li>

                <li>
                  <Link to={"#"}>
                    Previous Developments <i className="fa fa-chevron-down"></i>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <a href="/bradford-road-2">Bradford Road Phase 2</a>
                    </li>
                    <li>
                      <a href="/bradford-road">Bradford Road Phase 1</a>
                    </li>

                    <li>
                      <a href="/mont-blanc-height">Mont Blanc Heights</a>
                    </li>
                    <li>
                      <a href="/mont-saint-michel">Mont Saint Michel</a>
                    </li>

                    {/* <li>
                      <a href="/hyde-park-corner">Hyde Park Corner</a>
                    </li>
                    <li>
                      <a href="/ridge-road">Ridge Road</a>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to={"#"}>
                    Current Portfolio<i className="fa fa-chevron-down"></i>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <a href="/nicol-corner">Nicol Corner</a>
                    </li>
                    <li>
                      <a href="/ardenwold-manor">Ardenwold Manor</a>
                    </li>

                    {/* <li>
                      <a href="/hyde-park-corner">Hyde Park Corner</a>
                    </li>
                  */}
                  </ul>
                </li>
                <li>
                  <Link to={"#"}>
                    Developments In Progress
                    <i className="fa fa-chevron-down"></i>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <a href="/hyde-park-corner">Hyde Park Corner</a>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link to={"#"}>
                    Blog<i className="fa fa-chevron-down"></i>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={"/blog-grid"}>Blog Grid</Link>
                    </li>
                    <li>
                      <Link to={"/blog-list"}>Blog List</Link>
                    </li>
                    <li>
                      <Link to={"/blog-masonry"}>Blog Masonry</Link>
                    </li>
                    <li>
                      <Link to={"/blog-details"}>Blog Details</Link>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <Link to={"/contact-us"}>Contact Us</Link>
                </li>
              </ul>
              <div className="dlab-social-icon" style={{ display: "none" }}>
                <ul>
                  <li>
                    <Link
                      to={"#"}
                      className="site-button circle fa fa-facebook"
                    ></Link>
                  </li>
                  <li>
                    <Link
                      to={"#"}
                      className="site-button  circle fa fa-twitter"
                    ></Link>
                  </li>
                  <li>
                    <Link
                      to={"#"}
                      className="site-button circle fa fa-linkedin"
                    ></Link>
                  </li>
                  <li>
                    <Link
                      to={"#"}
                      className="site-button circle fa fa-instagram"
                    ></Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { HeaderContent };
export default Header;
