import React, { Component, useEffect } from "react";
import Slider from "react-slick";
import WindowSize from "../modules/windowSize";

// import  galery1 from './../../images/gallery/gallery-6/pic1.jpg';
// import  galery2 from './../../images/gallery/gallery-6/pic2.jpg';
// import  galery3 from './../../images/gallery/gallery-6/pic3.jpg';
// import  galery4 from './../../images/gallery/gallery-6/pic4.jpg';

import conferenceRoom from "./../../images/ium-temp-images/conference-room.jpg";
import reception from "./../../images/ium-temp-images/ium-reception.jpg";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev la la-angle-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

const Image = () => {
  const device = WindowSize();
  console.log(device);
  return (
      <img
        src="https://cdn.uberpd.co.za/img/logos/%C3%9Cber-Property-Developments-Logo.png"
        alt=""
        style={{width: device.width >= 760 ? "60%" : "100%", paddingLeft: device.width >= 760 ? "":'15%', paddingRight: device.width >= 760 ? "":'15%'  }}
      />
  )
}
class ExhibitionSlider1 extends Component {

  render() {
    var settings = {
      arrows: false,
      dots: false,
      slidesToShow: 1,
      infinite: false,
      autoplay: false,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    
    return (
      <Slider className="owl-carousel owl-none m-b30 " {...settings}>
        <div className="item" >
          <Image />
        </div>
      </Slider>
    );
  }
}

export default ExhibitionSlider1;
