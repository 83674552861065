import React, { Fragment, Component } from "react";
import WOW from "wowjs";
import Header from "../../Layout/Header";
import { ProDetail } from "./Data";
import Detail3Owl from "./Detail3Owl";
import Header2 from "../../Layout/Header2";
import YouTube from "react-youtube";

const detailBlog = [
  {
    icon: <i className="ti ti-user" />,
    title: "ARCHITECT",
    subtitle: "Cimato Moroldo",
  },
  {
    icon: <i className="ti ti-location-pin" />,
    title: "LOCATION",
    subtitle: "Bedfordview, South Africa",
  },
  {
    icon: <i className="ti ti-ruler-alt-2" />,
    title: "SIZE",
    subtitle: "4 600 sqm",
  },
  {
    icon: <i className="ti ti-home" />,
    title: "TYPE",
    subtitle: "Commercial",
  },
];

const latestBlog = [
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/new/bradford-road-1 (12).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/new/bradford-road-1 (2).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/new/bradford-road-1 (3).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/new/bradford-road-1 (4).jpg",
  },

  {
    image:
      "https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/new/bradford-road-1 (6).jpg",
  },

  {
    image:
      "https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/new/bradford-road-1 (8).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/new/bradford-road-1 (9).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/new/bradford-road-1 (10).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/new/bradford-road-1 (11).jpg",
  },
];
class BradfordRoad extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    return (
      <Fragment>
        <Header2 />
        <div className="page-content bg-white">
          {/*  banner  */}
          <div
            className="banner-three dlab-bnr-inr"
            style={{
              backgroundImage:
                "url(https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/bradford-road-1-header.jpg)",
              backgroundSize: "100% 100%",
            }}
            data-content="HOME"
            id="sidenav_home"
          ></div>
          {/*  Project Img  */}
          {/*  Info Carousel  */}
          <div
            className="section-full content-inner-2 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div
              className="container-fluid"
              style={{ maxWidth: "1700px", margin: "auto" }}
            >
              <Detail3Owl latestBlog={latestBlog} />
            </div>
          </div>
          {/*  Info Carousel */}

          {/*  Project Info  */}
          <section className="section-full content-inner">
            <div className="container">
              <div className="section-head">
                <span>DEVELOPED 2008</span>
                <h4 className="title">BRADFORD ROAD PHASE 1</h4>
                <button
                  style={{
                    backgroundColor: "#0d8a45",
                    border: "none",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    fontWeight: "bold",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="https://cdn.uberpd.co.za/img/projects/BRADFORD-ROAD/Habitat-article.pdf"
                    target="_blank"
                    style={{
                      color: "#ffffff",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    Explore Our Habitat Article Feature
                  </a>
                </button>
              </div>
              <p>
                This three-level office block combines functionality with
                elegance, offering a premier workspace in a prime location. It
                reflects UBER Property Development’s commitment to blending
                sophistication with practicality.
              </p>

              <p>
                <b>Property Overview</b>
              </p>
              <p
                className="m-b30"
                style={{ listStyleType: "disc", marginLeft: "3%" }}
              >
                - The building features a modern design focused on flexibility
                and efficiency, providing spacious office environments across
                all three floors.
              </p>
              <p
                className="m-b30"
                style={{ listStyleType: "disc", marginLeft: "3%" }}
              >
                - Advanced construction techniques and high-quality finishes are
                incorporated throughout.
              </p>
              <p
                className="m-b30"
                style={{ listStyleType: "disc", marginLeft: "3%" }}
              >
                - Includes a restaurant exclusively for corporate tenants.
              </p>
              <p
                className="m-b30"
                style={{ listStyleType: "disc", marginLeft: "3%" }}
              >
                - Basement parking offers ample space for tenants and visitors,
                ensuring convenience and accessibility.
              </p>
              <p>
                <b>Location and Accessibility</b>
              </p>

              <p className="m-b30">
                Strategically located in Bedfordview, Bradford Road Offices
                benefits from its proximity to major transport routes and
                amenities. This well-connected area offers easy access to key
                business districts, shopping centres, and essential services,
                making it an ideal choice for businesses seeking a prestigious
                and accessible location.
              </p>
              <div className="row m-b30 widget widget_getintuch widget_getintuch-pro-details pro-details-col-5 m-lr0">
                <ProDetail detailBlog={detailBlog} />
              </div>
            </div>
          </section>
          {/*  Project Info  End*/}
        </div>
      </Fragment>
    );
  }
}

export default BradfordRoad;
