import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";

import img1 from "./../../../images/projects/project-slide/img1.jpg";
import img2 from "./../../../images/projects/project-slide/img2.jpg";
import img3 from "./../../../images/projects/project-slide/img3.jpg";

const latestBlog = [
  { image: img1 },
  { image: img2 },
  { image: img3 },
  { image: img2 },
];
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev la la-angle-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

//Light Gallery on icon click
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  console.log(props);
  return (
    <Link
      to={"#"}
      onClick={() => openLightbox(props.latestBlog.image)}
      className="lightimg "
    >
      <i className="ti-zoom-in icon-bx-xs" />
    </Link>
  );
};

class Slider1 extends Component {
  render() {
    const { latestBlog } = this.props;

    var settings = {
      arrows: true,
      centerMode: true,
      centerPadding: "500px",
      slidesToShow: 1,
      infinite: true,
      navSpeed: 6000,
      autoplaySpeed: 6000,
      infinite: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 1,
            centerPadding: "400px",
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            centerPadding: "250px",
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            centerPadding: "250px",
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: "200px",
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            centerPadding: "90px",
          },
        },
      ],
    };
    return (
      <SimpleReactLightbox>
        <SRLWrapper>
          <Slider
            className="amenities-carousel owl-carousel owl-btn-center-lr "
            {...settings}
          >
            {latestBlog.map((item, index) => (
              <div className="items" key={index}>
                <div
                  className="amenit-box"
                  style={{ border: "0px solid #fff" }}
                >
                  <div className="media">
                    <img
                      src={item.image}
                      alt=""
                      style={{
                        width: "100%",
                        height: "400px",
                        objectFit: "cover",
                      }}
                      className="sliderstuff2"
                    />
                    <div className="overlay-bx">
                      <span
                        data-exthumbimage="images/projects/project-slide/img1.jpg"
                        data-src="images/projects/project-slide/img1.jpg"
                        className="lightimg"
                        title="Title Come Here"
                      >
                        <Iconimage latestBlog={latestBlog} />{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </SRLWrapper>
      </SimpleReactLightbox>
    );
  }
}

export default Slider1;
