import React, { Fragment, Component } from "react";
import WOW from "wowjs";
import Header from "../../Layout/Header";
import { ProDetail } from "./Data";
import Detail3Owl from "./Detail3Owl";
import Header2 from "../../Layout/Header2";
import YouTube from "react-youtube";

const detailBlog = [
  {
    icon: <i className="ti ti-user" />,
    title: "ARCHITECT",
    subtitle: "MONT BLANC Projects & Properties",
  },
  {
    icon: <i className="ti ti-location-pin" />,
    title: "LOCATION",
    subtitle: "Bedfordview, South Africa",
  },
  {
    icon: <i className="ti ti-ruler-alt-2" />,
    title: "SIZE",
    subtitle: "25 000 sqm",
  },
  {
    icon: <i className="ti ti-home" />,
    title: "TYPE",
    subtitle: "Residential",
  },
];

const latestBlog = [
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/MONT-BLANC-HEIGHTS/new/mont-blanc-heights-apartments-gallery-117384-20150219131323.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/MONT-BLANC-HEIGHTS/new/mont-blanc-heights-apartments-gallery-117393-20150219131405.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/MONT-BLANC-HEIGHTS/new/pic-mont-blanc-heights-apartments-bedfordview-9.jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/MONT-BLANC-HEIGHTS/new/Mont-Blanc-Heights-Apartments-Bedfordview-Exterior.jpg",
  },

  {
    image:
      "https://cdn.uberpd.co.za/img/projects/MONT-BLANC-HEIGHTS/new/Mont-Blanc-Heights-Apartments-Bedfordview-Exterior (1).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/MONT-BLANC-HEIGHTS/new/Mont-Blanc-Heights-Apartments-Bedfordview-Exterior (4).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/MONT-BLANC-HEIGHTS/new/Mont-Blanc-Heights-Apartments-Bedfordview-Exterior (3).jpg",
  },
  {
    image:
      "https://cdn.uberpd.co.za/img/projects/MONT-BLANC-HEIGHTS/new/Mont-Blanc-Heights-Apartments-Bedfordview-Exterior (2).jpg",
  },
];
class MontBlancHeight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: false,
      posterVisible: false,
      buttonVisible: true,
    };
  }
  componentDidMount() {
    new WOW.WOW().init();
  }
  handleClick = () => {
    this.setState((prevState) => ({
      showVideo: !prevState.showVideo,
      posterVisible: false,
    }));
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      showVideo: prevState.showVideo,
      posterVisible: !prevState.posterVisible,
      buttonVisible: false,
    }));
  };
  render() {
    return (
      <Fragment>
        <Header2 />
        <div className="page-content bg-white">
          {/*  banner  */}
          <div
            className="banner-three dlab-bnr-inr"
            style={{
              backgroundImage:
                "url(https://cdn.uberpd.co.za/img/projects/MONT-BLANC-HEIGHTS/299290859_150028967707521_674767665596506959_n-export.jpg)",
            }}
            data-content="HOME"
            id="sidenav_home"
          />
          {/*  Project Img  */}
          {/*  Info Carousel  */}
          <div
            className="section-full content-inner-2 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div
              className="container-fluid"
              style={{ maxWidth: "1700px", margin: "auto" }}
            >
              <Detail3Owl latestBlog={latestBlog} />
            </div>
          </div>
          {/*  Info Carousel */}

          {/*  Project Info  */}
          <section className="section-full content-inner">
            <div className="container">
              <div className="section-head">
                <span>DEVELOPED 2007</span>
                <h4 className="title">MONT BLANC HEIGHTS</h4>
                {this.state.buttonVisible && (
                  <button
                    onClick={this.handleToggle}
                    style={{
                      backgroundColor: "#0d8a45",
                      border: "none",
                      borderRadius: "5px",
                      padding: "10px 20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      style={{
                        color: "#ffffff",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      Click Here To Watch Our Video
                    </a>
                  </button>
                )}

                <div style={{ position: "relative" }}>
                  {this.state.showVideo && (
                    <YouTube className="iframe-youtube" videoId="2mpy6-Y9dho" />
                  )}
                  {this.state.posterVisible && (
                    <div onClick={this.handleClick} className="poster-image">
                      <img
                        src="https://cdn.uberpd.co.za/img/yotube-posters /pngegg.png"
                        alt="Play Button"
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <p>
                Situated at the corner of Oxford and Sovereign Roads,
                Bedfordview, Mont Blanc Heights redefines urban living with its
                sophisticated design and prime location. This landmark
                development features a modern 11-storey high-rise with a focus
                on luxury and functionality, offering a range of residential
                options including exclusive 2 and 3-bedroom apartments,
                penthouses with private rooftop terraces, and ground-floor
                garden apartments.
              </p>
              <h3>
                <b>Key Features</b>
              </h3>
              <p
                style={{
                  listStyleType: "disc",
                  marginLeft: "3%",
                  marginTop: "3%",
                }}
              >
                <b>Prime Location:</b> Easily accessible from the R24, N3, and
                M1, with Eastgate Shopping Centre, New Bedford Square Centre,
                Bedford Centre, and Bedford Gardens Hospital nearby.
              </p>
              <p
                style={{ listStyleType: "disc", marginLeft: "3%" }}
                className="m-b30"
              >
                <b>Exclusive Residences:</b> Choose from 48 luxury apartments, 4
                penthouses, and 6 garden apartments, each designed with modern
                aesthetics and attention to detail.
              </p>
              <p
                style={{ listStyleType: "disc", marginLeft: "3%" }}
                className="m-b30"
              >
                <b>Smart Technology:</b> All apartments are equipped with Smart
                Technology™, including 24-hour internet connectivity, integrated
                access control, state-of-the-art security systems, and home
                theatre readiness.
              </p>
              <p
                style={{ listStyleType: "disc", marginLeft: "3%" }}
                className="m-b30"
              >
                <b>High-Quality Finishes:</b> Enjoy contemporary living with
                wooden Venetian blinds, imported porcelain tiles,
                top-of-the-line Whirlpool appliances, and designer bathrooms
                with chrome jetted spa baths.
              </p>
              <p
                style={{ listStyleType: "disc", marginLeft: "3%" }}
                className="m-b30"
              >
                <b>Modern Amenities:</b> The development features a clubhouse,
                beach pool, beach volleyball court, squash court, tropical
                gardens, and a 24-hour concierge service.
              </p>
              <p className="m-b30">
                Mont Blanc Heights offers a sophisticated, "lock-up-and-go"
                lifestyle with a focus on luxury and security. With construction
                set to begin early next year, only 58 apartments will be
                available, making this a highly sought-after opportunity.
                Reserve your place in this premier development and experience
                the epitome of modern urban living.
              </p>
              <div className="row m-b30 widget widget_getintuch widget_getintuch-pro-details pro-details-col-5 m-lr0">
                <ProDetail detailBlog={detailBlog} />
              </div>
            </div>
          </section>
          {/*  Project Info  End*/}
        </div>
      </Fragment>
    );
  }
}

export default MontBlancHeight;
