import React, { Fragment, Component } from "react";
import WOW from "wowjs";
import { ProDetail } from "./Data";
import Detail3Owl from "./Detail3Owl";

const detailBlog = [
  {
    icon: <i className="ti ti-user" />,
    title: "ARCHITECT",
    subtitle: "Cimato Moroldo",
  },
  {
    icon: <i className="ti ti-location-pin" />,
    title: "LOCATION",
    subtitle: "Mountain View, South Africa",
  },
  {
    icon: <i className="ti ti-ruler-alt-2" />,
    title: "SIZE",
    subtitle: "4 000 sqm (combined under-roof) ",
  },
  {
    icon: <i className="ti ti-home" />,
    title: "TYPE",
    subtitle: "Residential",
  },
];
class ArdenwoldManor extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    return (
      <Fragment>
        <div className="page-content bg-white">
     
          {/*  Project Img  */}
          {/*  Info Carousel  */}
          <div
            className="section-full content-inner-2 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="container-fluid">
              <Detail3Owl />
            </div>
          </div>
          {/*  Info Carousel */}

          {/*  Project Info  */}
          <section className="section-full content-inner">
            <div className="container">
              <div className="section-head">
                <span>UNDER CONSTRUCTION</span>
                <h4 className="title">RIDGE ROAD</h4>
              </div>
              <p>
                The Ridge Road development in Mountain View stands as the
                epitome of luxury and sophistication. Nestled in the heart of
                one of Johannesburg's heritage suburb, this exceptional property
                seamlessly blends historical elegance with contemporary
                opulence. Favoured by renowned architect Herbert Baker and the
                affluent Randlords, Mountain View is a leafy, jacaranda-lined
                area, making it one of the most sought-after locations in the
                city. 
              </p>
              <h3 className="m-b30">
                <b>Property Overview</b>
              </h3>
              <p>
                <b>Main House</b>
              </p>

              <p className="m-b30">
                The main house is two storeys, showcasing a high-end renovation
                where no expense was spared. The first floor boasts luxurious
                living spaces, a state-of-the-art kitchen, and an exquisite wine
                cellar, while the second storey features four luxurious en-suite
                bedrooms. The finishes are custom-made and imported from
                Germany, Italy, and Spain. Additionally, it is a fully automated
                smart house offering seamless control over lighting, climate,
                and security. 
              </p>

              <p>
                <b>Additional Structures</b>
              </p>
              <ul style={{ listStyleType: "disc" }} className="m-b30">
                <li
                  className="m-b30"
                  style={{ listStyleType: "disc", marginLeft: "3%" }}
                >
                  <b>Staff Quarters:</b> A fully restored heritage home
                  preserving the historical charm of the property. 
                </li>
                <li
                  className="m-b30"
                  style={{ listStyleType: "disc", marginLeft: "3%" }}
                >
                  <b>Cottages:</b> Two double-storey cottages providing
                  additional living space or guest accommodation. 
                </li>
                <li
                  className="m-b30"
                  style={{ listStyleType: "disc", marginLeft: "3%" }}
                >
                  <b>Guard House:</b> Ensuring round-the-clock security and
                  peace of mind. 
                </li>{" "}
                <li
                  className="m-b30"
                  style={{ listStyleType: "disc", marginLeft: "3%" }}
                >
                  <b>Multi-Car Garage:</b> Ample space for a collection of
                  vehicles. 
                </li>{" "}
                <li
                  className="m-b30"
                  style={{ listStyleType: "disc", marginLeft: "3%" }}
                >
                  <b>Amenities:</b> Includes an indoor swimming pool, padel
                  court and shooting range for recreation and leisure. 
                </li>{" "}
              </ul>

              <p>
                <b>Location and Accessibility</b>
              </p>

              <p className="m-b30">
                Mountain View, with its rich cultural heritage and stunning
                natural beauty, offers an unparalleled living experience. The
                area is known for its architectural significance and tranquil
                environment, providing residents with a serene yet prestigious
                setting. The strategic location ensures easy access to
                Johannesburg's major business districts, shopping centres, and
                top educational institutions.  <br />
                <br />
                The Ridge Road development is not just a home; it's a
                masterpiece of luxury living, offering the finest in modern
                convenience and historical charm. This property is ideal for
                those seeking an exclusive and sophisticated lifestyle in one of
                Johannesburg's most prestigious suburbs. 
              </p>
              <div className="row m-b30 widget widget_getintuch widget_getintuch-pro-details pro-details-col-5 m-lr0">
                <ProDetail detailBlog={detailBlog} />
              </div>
            </div>
          </section>
          {/*  Project Info  End*/}
        </div>
      </Fragment>
    );
  }
}

export default ArdenwoldManor;
