import React, { Fragment } from "react";

const ComingSoon = () => {
  return (
    <Fragment>
      <div className="content-block">
        <div className="coming-soon bg-light">
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "linear-gradient(180deg, white 0%, #DBDBDB 100%)",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src="https://cdn.uberpd.co.za/img/logos/%C3%9Cber-Property-Developments-Logo.png"
                alt="Über Property Developments Logo"
                style={{
                  width: "347px",
                  height: "404px",
                }}
              />
              <div style={{ width: "100%", marginTop: "10%" }}>
                <div
                  style={{
                    textAlign: "center",
                    color: "black",
                    fontSize: 30,
                    fontWeight: "400",
                    textTransform: "uppercase",
                    letterSpacing: 15,
                    paddingLeft: "22px",
                    wordWrap: "break-word",
                  }}
                >
                  Launching Soon
                </div>
                <a
                  href="https://www.antonioiozzo.com/BusinessPortfolio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    style={{
                      marginTop: "7%",
                      textAlign: "center",
                      color: "black",
                      fontSize: 15,
                      fontWeight: "600",
                      textTransform: "uppercase",
                      letterSpacing: 7.5,
                      wordWrap: "break-word",
                    }}
                  >
                    return
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ComingSoon;
