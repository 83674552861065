import React, { Fragment, Component } from "react";
import WOW from "wowjs";

//images/

import Header2 from "../../Layout/Header2";

import sendgrid from "@sendgrid/mail";

sendgrid.setApiKey(
  "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"
);

const sendEmail = async ({
  firstName,
  lastName,
  emailAddress,
  phoneNumber,
  message,
}) => {
  if (
    typeof firstName === "string" &&
    typeof lastName === "string" &&
    typeof emailAddress === "string" &&
    typeof phoneNumber === "string" &&
    typeof message === "string"
  ) {
    try {
      await sendgrid.send({
        to: "vuyo@ium.co.za", // Your email where you'll receive emails
        from: "no-reply@ium.co.za", // your website email address here
        subject: "New contact form submission for Antonio Iozzo.",
        html:
          "From: " +
          firstName +
          " " +
          lastName +
          "<br> Email: " +
          emailAddress +
          "<br> Cell: " +
          phoneNumber +
          "<br> Message: " +
          message,
      });
      return { success: true };
    } catch (error) {
      console.error(error.response.body);
      return { error: true };
    }
  }
  return { error: true };
};

class ContactUs1 extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submitted");

    const formData = new FormData(event.target);
    const firstName = formData.get("dzFirstName");
    const lastName = formData.get("dzLastName");
    const phoneNumber = formData.get("dzphone");
    const emailAddress = formData.get("dzEmail");
    const message = formData.get("dzMessage");
    console.log(
      "Form data:",
      firstName,
      lastName,
      phoneNumber,
      emailAddress,
      message
    );

    try {
      const response = await sendEmail({
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        message,
      });
      if (response.success) {
        alert("Form submitted successfully!");
      } else {
        alert("Form submitted successfully!");
      }
    } catch (error) {
      console.error(error);
      alert("Form submitted successfully!");
    } finally {
      event.target.reset(); // clear form selections
    }
  };

  render() {
    return (
      <Fragment>
        <Header2 />
        <div className="page-content bg-white">
          <div
            className="banner-three dlab-bnr-inr"
            style={{
              backgroundImage:
                "url(https://cdn.uberpd.co.za/img/contactus/DSC6057-export.jpg)",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            data-content="HOME"
            id="sidenav_home"
          ></div>{" "}
          {/*  Inner Page Banner */}
          <section className="content-inner-1" data-content="">
            <div className="container">
              <div
                className="row align-items-center"
                style={{ justifyContent: "center" }}
              >
                <div className="col-lg-10 col-md-7 col-sm-12 m-b30">
                  <form
                    className="contact-box dzForm p-a30 border-1"
                    onSubmit={this.handleSubmit}
                  >
                    <h3 className="title-box">Contact Us </h3>
                    <div className="dzFormMsg m-b20"></div>
                    <input type="hidden" value="Contact" name="dzToDo" />
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="dzFirstName"
                              type="text"
                              required=""
                              className="form-control"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="dzLastName"
                              type="text"
                              required=""
                              className="form-control"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="dzphone"
                              type="text"
                              required=""
                              className="form-control"
                              placeholder="Phone"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="dzEmail"
                              type="email"
                              className="form-control"
                              required=""
                              placeholder="Your Email Id"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <textarea
                              name="dzMessage"
                              rows="4"
                              className="form-control"
                              required=""
                              placeholder="Your message"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <button
                          name="submit"
                          type="submit"
                          value="Submit"
                          className="btn btn-primary btn-lg"
                        >
                          Submit{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    );
  }
}
export default ContactUs1;
